@use '@angular/material' as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$gatefy-primary: mat.define-palette(mat.$blue-palette, 500);
$gatefy-accent: mat.define-palette(mat.$blue-palette, 500);
$gatefy-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as `color` or `typography`.
$gatefy-theme: mat.define-light-theme(
  (
    color: (
      primary: $gatefy-primary,
      accent: $gatefy-accent,
      warn: $gatefy-warn,
    ),
  )
);

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($gatefy-theme);

.no-input-btn {
  height: 0 !important;
  width: 0 !important;
  .mat-date-range-input-container {
    .mat-date-range-input-separator {
      display: none !important;
    }
  }
}

.custom-separator {
  .mat-date-range-input-container {
    justify-content: flex-end !important;
  }
}

mat-datepicker-toggle {
  .mat-icon-button {
    border-radius: 4px;
    border: 1px solid var(--primary-color);

    .mat-datepicker-toggle-default-icon {
      color: var(--primary-color);
    }

    .mat-button-focus-overlay {
      background-color: transparent !important;
    }
  }

  & :hover {
    background-color: var(--primary-color);

    .mat-datepicker-toggle-default-icon {
      color: var(--bg-color);
    }
  }

  & button:focus {
    outline: 0 !important;
    border: 1px solid var(--primary-color) !important;
  }
}

.mat-calendar-body-selected {
  background-color: var(--primary-color);

  & :hover {
    background: rgb(0 102 255 / 20%);
  }
}

.mat-calendar-body-in-range::before {
  background: rgb(0 102 255 / 20%);
}

.mat-calendar-body-cell:not(.mat-calendar-body-disabled):hover
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-keyboard-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical),
.cdk-program-focused
  .mat-calendar-body-active
  > .mat-calendar-body-cell-content:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
  background-color: rgb(0 102 255 / 20%);
}
