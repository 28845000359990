@import 'gatefy';
@import 'icon';
@import 'modal';
@import 'lf';
@import 'bootstrap';
@import 'arrow';
@import 'badge';
@import 'checkbox';
@import 'custom';
@import 'dragAndDrop';
@import 'body';
@import 'arrow-effect';
@import 'legends';
@import 'errors';
@import 'tables';
@import 'sweet-alert';
@import 'scrollbars';
@import 'toastr';

@import 'search-tag/main';
@import 'angular-material-theme/gatefy-theme';

html {
  overflow-y: scroll;
}
