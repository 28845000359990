/*--- BootStrap overwrite ---*/

//.col-1,
//.col-2,
//.col-3,
//.col-4,
//.col-5,
//.col-6,
//.col-7,
//.col-8,
//.col-9,
//.col-10,
//.col-11,
//.col-12 {
//  float: left;
//  overflow: hidden;
//}
//
//.col-12 {
//  padding: 0px !important;
//}

//.col-1:first-child,
//.col-2:first-child,
//.col-3:first-child,
//.col-4:first-child,
//.col-5:first-child,
//.col-6:first-child,
//.col-7:first-child,
//.col-8:first-child,
//.col-9:first-child,
//.col-10:first-child,
//.col-11:first-child {
//  padding-left: 0px !important;
//}
//
//.col-1:last-child,
//.col-2:last-child,
//.col-3:last-child,
//.col-4:last-child,
//.col-5:last-child,
//.col-6:last-child,
//.col-7:last-child,
//.col-8:last-child,
//.col-9:last-child,
//.col-10:last-child,
//.col-11:last-child {
//  padding-right: 0px !important;
//}

//.col-12 {
//  padding: 0px !important;
//}

.ngb-modal-component .modal.fade.show {
  background-color: rgba(0, 0, 0, 0.5);
}

ngb-modal-window {
  background: white;
}

ngb-alert {
  position: absolute;
  width: 50%;
  z-index: 1;
}

ngb-progressbar {
  position: absolute;
  width: 100%;
}

ngb-progressbar .progress {
  border-radius: 0;
}

ngb-pagination .pagination > li.page-item.active > a.page-link {
  background: #6c757d;
  border-color: #6c757d;
  color: #ffffff;
}

ngb-pagination .pagination > li.page-item.active > a.page-link:active {
  box-shadow: 0 0 0 0.2rem rgba(108, 117, 125, 0.5);
}

ngb-pagination .pagination > li.page-item > a.page-link,
ngb-tabset .nav-tabs .nav-link.disabled,
ngb-tabset .nav-tabs .nav-link.disabled:hover,
ngb-tabset .nav-tabs .nav-link.disabled:focus,
ngb-tabset .nav-tabs .nav-link.disabled:active {
  color: #6c757d !important;
}

ngb-tabset .nav-tabs .nav-link {
  color: #546da0 !important;
}

ngb-tabset .nav-tabs {
  border-bottom: 1px solid #aabcd6;
}

ngb-tabset .nav-tabs .nav-link:hover,
ngb-tabset .nav-tabs .nav-link:focus,
ngb-tabset .nav-tabs .nav-link:active {
  color: #546da0;
}

ngb-tabset .nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  border-color: #aabcd6 #aabcd6 #fff;
}

ngb-datepicker-navigation .btn-link {
  color: #343a40 !important;
}

ngb-datepicker ngb-datepicker-month-view .bg-primary {
  background-color: #212529 !important;
}

ngb-datepicker ngb-datepicker-month-view .ngb-dp-weekday,
ngb-timepicker .btn-link {
  color: #6c757d !important;
}

ngb-timepicker .btn-link:hover {
  color: #212529 !important;
}

ngb-pagination .pagination > li.page-item.active > a.page-link {
  color: #fff !important;
}

input:checked + .slider {
  background-color: #2dae73;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2dae73;
}

input:checked + .slider:before {
  -webkit-transform: translateX(10px);
  -ms-transform: translateX(10px);
  transform: translateX(10px);
}

$enable-responsive-font-sizes: true;
