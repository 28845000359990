.swal2-container {
  .swal2-header {
    h1, h2, h3, h4, h5, h6 {
      color: #373A3C;
      margin-bottom: 16px;
      font-size: 24px;
    }
  }
  .swal2-content {
    margin-bottom: 16px;
    * {
      font-size: 16px;
      color: #373A3C;
    }
  }
}

.swal2-backdrop-show {
  backdrop-filter: blur(5px);
}