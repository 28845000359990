.badge-outline-danger {
  background: none;
  color: #dc3545;
  border: 1px solid #dc3545;
  font-weight: normal;
}

.badge-outline-success {
  background: none;
  color: #28a745;
  border: 1px solid #28a745;
  font-weight: normal;
}
