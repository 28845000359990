.gatefy-content-box {
  background: #fff;
  border-radius: 5px;
  margin: 0;
}

.gatefy-cursor-pointer {
  cursor: pointer;
}

.gatefy-droppable {
  background: #efefef;
  border-radius: 5px;
  height: 150px;
  overflow: auto;
  cursor: pointer;
}

.gatefy-droppable.disable {
  background-color: #b0b0b0;
  cursor: not-allowed;
}

.gatefy-droppable div {
  padding: 0;
  background-color: #b0b0b0;
  margin: 5px;
  display: flex;
  border-radius: 5px;
}

.gatefy-droppable div span {
  display: inline-flex;
  margin: 1px 0;
}

.gatefy-encapsulated thead tr {
  border-left: solid 1px #4a4a4a;
  border-right: solid 1px #4a4a4a;
}

.gatefy-modal-filter {
  position: absolute;
  margin-top: -59px;
  background-color: #4a4a4a;
  padding: 3px;
  border-radius: 5px;
  width: 183px;
  margin-left: -12px;
}

.gatefy-modal-filter input {
  padding: 6px 63px 6px 6px;
  float: right;
}

.gatefy-modal-filter .seta {
  border-top: 15px solid #4a4a4a;
  border-left: 15px solid transparent;
  border-right: 15px solid transparent;
  margin: 40px 0 0 77px;
  position: absolute;
}

.gatefy-modal-filter button {
  position: absolute;
  margin-top: 7px;
  right: 0;
}

.gatefy-not-encapsulated .gatefy-subtitle {
  margin: 2px 0;
}

.gatefy-not-encapsulated thead th:first-child {
  border-top-left-radius: 5px;
}

.gatefy-not-encapsulated thead th:last-child {
  border-top-right-radius: 5px;
}

.gatefy-not-encapsulated .gatefy-content-box {
  min-height: 418px;
}

.gatefy-not-encapsulated table {
  top: -10px !important;
}

.gatefy-radio {
  height: 28px;
}

.gatefy-radio label {
  display: inline-block;
  position: relative;
  padding-left: 26px;
  margin-top: 3px;
  margin-bottom: 2px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.gatefy-radio label p {
  margin: -8px 0 0;
}

.gatefy-radio label p small {
  margin-right: 16px;
}

/* Hide the browser's default radio button */
.gatefy-radio label input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.gatefy-radio label span {
  position: absolute;
  top: 0;
  left: 0;
  height: 22px;
  width: 22px;
  background-color: transparent;
  border-radius: 50%;
  border: 6px solid #b0b0b0;
}

/* On mouse-over, add a grey background color */
.gatefy-radio label:hover span {
  /*background-color: #ccc;*/
}

/* When the radio button is checked, add a green background */
.gatefy-radio label input:checked ~ span {
  background-color: #2dae73;
  border: 0;
  transition: all 0.2s;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.gatefy-radio label span:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.gatefy-radio label input:checked ~ span:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.gatefy-radio label span:after {
  top: 6px;
  left: 6px;
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: white;
}

.gatefy-subtitle {
  font-size: 32px;
  font-weight: bold;
  color: #546da0;
  position: absolute;
  top: 54px;
  left: 172px;
  padding: 0;
  &-messages {
    top: -8px;
  }
}

.floating-subtitle {
  position: absolute;
  transform: translateY(24px);
}

.gatefy-title {
  display: flex;
  font-size: 24px;
  font-weight: bold;
  padding: 0 0 10px;
  min-height: 30px;
}

.gatefy-title-save {
  position: absolute;
  top: 12px;
  right: 56px;
}

.gatefy-btn {
  margin: 0 0 0 10px;
  padding: 5px 20px;
  background: none;
  border-radius: 40px;
  font-size: 12px;
  -webkit-transition: background 1s;
  -moz-transition: background 1s;
  transition: background 1s;
}

.gatefy-btn-cancel {
  width: 100px;
  color: #cb5656;
  border: 1px solid #cb5656;
}

.gatefy-btn-cancel:hover {
  background: #cb5656;
  color: #fff;
}

.gatefy-btn-done {
  width: 100px;
  color: #2dae73;
  border: 1px solid #2dae73;
}

.gatefy-btn-done:hover {
  background: #2dae73;
  color: #fff;
}

.gatefy-btn-done:disabled {
  background: #e9ecef;
  border-color: black;
  color: black;
}

.gatefy-btn-add {
  padding: 2px 10px;
  border-radius: 5px;
  color: #000;
  border: 1px solid #000;
  margin: -11px 0 0 0;
}

.gatefy-btn-add:hover {
  background: #000;
  color: #fff !important;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.gatefy-btn-search {
  padding: 2px 10px;
  border-radius: 5px;
  color: #000;
  border: 1px solid #000;
  margin: 7px 7px 0 3px;
}

.gatefy-btn-search:hover {
  background: #000;
  color: #fff;
  -webkit-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.gatefy-btns {
  display: block;
  text-align: center;
  padding: 0 !important;
}

.gatefy-btns .gatefy-btn {
  display: inline-table;
  margin: 0 1px 1px;
}

.gatefy-btns .gatefy-btn span {
  width: 30px;
  border-radius: 0;
  font-size: 11px;
  border: #4a4a4a solid 1px;
  padding: 4px 0 5px;
  transition-duration: 500ms;
}

.gatefy-btns .gatefy-btn span:hover {
  background: #4a4a4a;
  color: #fff;
}

.gatefy-btns .gatefy-btn:not(:only-child):first-of-type span {
  border-radius: 5px 0 0 5px;
}

.gatefy-btns .gatefy-btn:not(:only-child):last-of-type span {
  border-radius: 0 5px 5px 0;
}

.gatefy-btns .gatefy-btn:only-child span {
  border-radius: 5px;
}

.gatefy-btns,
.gatefy-actions {
  width: 123px;
}

/*TODO: remove this .gatefy-checkbox and change all to component*/
.gatefy-checkbox {
  text-align: center;
  margin: 0 0 0 15px;
}

.gatefy-checkbox p {
  margin-bottom: 6px;
}

.gatefy-checkbox label {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 21px;
  top: 5px;
}

.gatefy-checkbox input {
  display: none;
}

.gatefy-checkbox span {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 34px;
  background-color: #b0b0b0;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.gatefy-checkbox span:before {
  position: absolute;
  content: '';
  height: 15px;
  width: 15px;
  left: 3px;
  bottom: 3px;
  border-radius: 50%;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.gatefy-checkbox input:checked + span {
  background-color: #2dae73;
}

.gatefy-checkbox input:disabled + span {
  background-color: black;
}

.gatefy-checkbox input:checked + span:before {
  -webkit-transform: translateX(11px);
  -ms-transform: translateX(11px);
  transform: translateX(11px);
}

.gatefy-content {
  margin-top: 132px;
  background: white;
  border-radius: 20px;
  box-shadow: 0px 0px 15px #00000010;
  padding: 24px;
  margin-left: 38px;
  width: calc(100% - 76px);
}
