
.input-group {
  .input-group-prepend {
    &:first-child {
      .input-group-text, .btn {
        @include border-left-radius(2px);
      }
    }
  }

  .input-group-append {
    &:last-child {
      .input-group-text, .btn {
        @include border-right-radius(2px);
      }
    }
  }
}
