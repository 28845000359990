.arrow-effect {
  i {
    transition: .2s;
  }
}

.arrow-effect:hover {
  i {
    transform: translateX(-5px);
  }
}