:root {
  --bg-color: #fafafa;
  --primary-color: #3d94ff;
  --color-secondary: #46494c;
  --little-animation: all ease-in-out 0.2s;
}

/* You can add global styles to this file, and also import other style files */
//::-webkit-scrollbar {
//  width: 2px;
//  height: 2px;
//  background: #ffcc00;
//}
//
//::-webkit-scrollbar-track {
//  -webkit-box-shadow: inset 0 0 6px #222;
//}
//
//::-webkit-scrollbar-thumb {
//  background-color: #ffcc00;
//  outline: 1px solid transparent;
//}

/* fonts */
@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro');

* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html,
body {
  background: var(--bg-color);
  font-family: 'Source Sans Pro', sans-serif;
  overflow-y: visible;
}

hr {
  margin: 6px 0;
}

p {
  margin-bottom: 6px;
}

a {
  outline-style: none;
}

div {
  outline-style: none;
}

/* For IE10 */
select::-ms-expand {
  display: none;
}

input {
  background: transparent;
  border: 0px solid transparent;
}

/*--- Switch ---*/
.switch {
  position: relative;
  display: inline-block;
  width: 32px;
  height: 22px;
  margin: 0px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}

.highlight {
  background-color: #92d3fc;
  color: #171f2b;
  font-weight: bold;
}

.slider:before {
  position: absolute;
  content: '';
  height: 18px;
  width: 18px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s ease-out;
  transition: 0.4s ease-out;
}

.full-table table thead tr th span i {
  border: 0px solid transparent;
}

.full-table table tbody tr td {
  color: #696969;
  font-weight: 400;
}

.table th,
.table td {
  vertical-align: baseline;
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.disable {
  cursor: not-allowed !important;
  pointer-events: none !important;
}

.form-row {
  margin: 0;
}

.card {
  border: 0px transparent;
}

.popover-card-config {
  &.bs-popover-bottom-right > .arrow,
  &.bs-popover-top-right > .arrow {
    right: 0.25rem;
  }
}

@media (max-width: 575px) {
  .padSm {
    padding: 0 !important;
  }
}

.switch-fit-content {
  width: fit-content;
}

.loading-spinner {
  height: 24px;
}

.btn-custom {
  width: 4rem;
  height: 40px;
}

.mat-tooltip {
  font-size: 12px !important;
}
