.form-control {
  height: 40px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.06) !important;
  border: 1px solid #d5d5d5;
  background: white;
  font-size: 14px;
  letter-spacing: 0px;
  color: #546da0 !important;
  border-radius: 2px;
  outline: none;
  padding: 0px 12px;
  margin-bottom: 16px;

  &::placeholder {
    letter-spacing: 0px;
    color: #aabcd5;
  }

  &:read-only {
    background: white;
  }

  &:disabled {
    background: #f1f1f1;
    border: none;
  }

  &.is-invalid:not(form) {
    &::placeholder {
      letter-spacing: 0px;
      color: #dc3545bf;
    }
  }
}

textarea.form-control {
  padding-top: 10px;
  padding-bottom: 10px;
}

select.form-control {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='gray' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 99%;
  background-position-y: 10px;
}
