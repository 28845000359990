.form-row {
  legend {
    color: #546da0;
    margin-top: 8px;
  }

  label {
    color: #546da0;
    margin-bottom: 0;
  }

  .description {
    color: #aabcd5 !important;
    margin-top: 0.3rem;
  }
}

.description {
  color: #aabcd5 !important;
  font-size: 16px;
  cursor: inherit;
  font-weight: normal !important;
}

.subtitle {
  display: flex;
  flex-direction: column;
  margin-bottom: 1rem;
}
