.modal-dialog {
  width: 100%;
  max-width: none !important;
  height: 100%;
  margin: 0;

  .modal-content {
    padding: 32px 32px 0px 32px;
    height: 100%;
    border: none;
  }
}

.modal-xsm .modal-dialog .modal-content,
.modal-xlg .modal-dialog .modal-content {
  border-radius: 2px;
  padding: 3% 8.25%;
  padding-bottom: 0;
}

.custom {
  height: fit-content;
  max-height: 90%;
  width: 70%;
  margin: 2.5% 15%;

  .modal-dialog .modal-content {
    padding: 4.5% 8.25% 2px;
  }
}

/* Safari 11+ */
@media not all and (min-resolution: .001dpcm) {
  @supports (-webkit-appearance:none) and (stroke-color:transparent) {
    .custom {
      height: -webkit-fill-available;
    }
  }
}

.mfa-modal {
  width: 50% !important;
  margin: 2.5% 25% !important;
  padding: 0 !important;

  .modal-dialog .modal-content {
    padding: 2.5% 2.25% 2px 4.25%;
  }
}

.validate-modal {
  width: 50% !important;
  margin: 20% 25% !important;
  padding: 0 !important;

  .modal-dialog .modal-content {
    padding: 2.5% 2.25% 2px 4.25%;
  }
}

.domain-verify {
  height: fit-content;
  max-height: 90%;
  width: fit-content;
  min-width: 50%;
  margin: 2.5% 25%;
}

.modal-xxl .modal-lg {
  max-width: 1000px;
  width: 1000px !important;
}

.modal-xxl .modal-lg .modal-content {
  border-top: 5px solid #ffcc00;
  background: #efefef;
  border-radius: 0 0 5px 5px;
}

.modal-xxl2 {
  max-width: 600px;
  margin: 0 calc(50% - 300px);
}

.modal-xxl2 .modal-lg .modal-content {
  border-top: 5px solid #ffcc00;
  background: #efefef;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.modal-backdrop.show {
  opacity: 0.6;
}

.modal-header {
  border: none;
  padding: 0 0 15px;

  .modal-title {
    color: #546da0 !important;
    font-weight: bold !important;
    font-size: 2em !important;
  }
}

.modal-body {
  padding: 24px 24px 16px 24px;
}

.close {
  outline: none !important;
}

.modal-content {
  .close {
    outline: none !important;
    font-size: 60px;
    margin-top: -28px;
    margin-right: 1.6%;
    font-weight: 50;
    color: #aabcd5;
  }
}

.modal-footer {
  padding: 16px 0px 58px;
  text-align: center;
  display: block;
  border: none;

  button {
    width: 129px;
    margin: 0px 13px;
    height: 43px;
    line-height: 0;

    &.cancel-btn {
      background: #f4f4f4;
    }
  }
}
