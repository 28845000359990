
.lf_dashboard {
  float: left;
  width: 1200px;
  height: auto;
  margin: 0 calc(50% - 600px);
  background: #efefef;
  border-radius: 0 0 5px 5px;
}

.lf_dashboard_title {
  float: left;
  width: 100%;
  height: 60px;
  padding: 0px 50px !important;
}

.lf_h1 {
  float: left;
  width: auto;
  height: 60px;
  line-height: 60px;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #000000;
}

.lf_h2_preTable {
  float: left;
  width: auto;
  height: auto;
  line-height: 20px;
  font-size: 20px;
  font-weight: 700;
  text-align: left;
  color: #000000;
  padding: 36px 0 17px 25px;
}

.lf_card_btn {
  float: right;
  min-width: 22px;
  height: 22px;
  background: #ffffff;
  border-radius: 4px;
  margin: 36px 0 0 15px;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -ms-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  cursor: pointer;
}

.lf_todo_btn {
  float: left;
  width: 22px;
  height: 22px;
  background: #ffffff;
  margin: 9px 2px 9px 0;
  -webkit-transition: 0.2s all;
  -moz-transition: 0.2s all;
  -ms-transition: 0.2s all;
  -o-transition: 0.2s all;
  transition: 0.2s all;
  cursor: pointer;
  border: 1px solid #000000;
}

.lf_todo_btn:first-child {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.lf_todo_btn:last-child {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.lf_btn_filter {
  border: 1px solid #000000;
  margin-right: 25px;
}

.lf_btn_up {
  border: 1px solid #000000;
}

.lf_btn_add {
  padding: 0 10px;
  font-size: 13px;
  line-height: 22px;
  border: 1px solid #000000;
}

.lf_card_btn:hover {
  background: #444444;
  color: #ffffff;
}

.lf_btn_add:hover {
}

.lf_card {
  float: left;
  width: calc(100% - 50px);
  height: auto;
  background: #ffffff;
  border-radius: 5px;
  -webkit-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 5px 1px rgba(0, 0, 0, 0.4);
  margin: 0 25px 50px 25px;
}

.lf_modal01 {
  float: left;
  width: 100%;
  height: auto;
  padding: 0px 50px !important;
}

.lf_modal_level01 {
  float: left;
  width: 1000px;
  height: auto;
  margin: 0 calc(50% - 500px);
  background: #efefef;
  border-top: 5px solid #ffcc00;
  border-radius: 0 0 5px 5px;
}

.lf_row {
  float: left;
  width: 100%;
  margin: 0px;
  padding: 20px 0 0 0;
}

.lf_card .lf_row {
  padding-top: 0;
}

.lf_titleline {
  float: left;
  margin: 0;
  padding: 45px 0px 10px 0px;
  width: 100%;
  height: 80px;
  border-bottom: 1px solid #888888;
}

.lf_sub_titleline {
  float: left;
  width: 100%;
  height: 35px;
}

.lf_h2_modal01 {
  float: left;
  width: calc(100% - 100px);
  height: 25px;
  font-size: 24px;
  font-weight: 700;
  text-align: left;
  color: #000000;
  line-height: 25px;
}

.lf_h3_modal01 {
  float: left;
  width: auto;
  height: 25px;
  font-size: 16px;
  font-weight: 700;
  text-align: left;
  color: #000000;
  line-height: 25px;
}

.lf_row02 {
  float: left;
  width: 100%;
  margin: 0 0 30px 0 !important;
  padding: 0;
}

.lf_row03 {
  float: left;
  width: 100%;
  margin: 0 0 0 0 !important;
  padding: 0;
}

.lf_float_width {
  float: left;
  width: 100%;
}

/*--- Table ---*/

.lf_card_table {
  float: left;
  width: 100%;
  height: auto;
}

.lf_role_table {
  float: left;
  width: 100%;
}

.lf_tr {
  float: left;
  width: 100%;
  min-height: 30px;
}

.lf_tr td {
  min-height: 30px;
}

.lf_tr_title {
  background: #444444;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.lf_td_top01 {
  float: left;
  width: 28.5714285714%;
  border-right: 1px solid #ffffff;
}

.lf_td_top02 {
  float: left;
  width: 14.2857142857%;
  border-right: 1px solid #ffffff;
}

.lf_td_top02:last-child {
  border-right: 0px solid transparent;
}

.lf_td_top {
  color: #ffffff;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
  font-size: 13px;
  border-right: 1px solid #ffffff;
  float: left;
}

.lf_td_top:last-child {
  border-right: 0px solid transparent;
}

.lf_td01 {
  float: left;
  height: 40px;
  color: #000000;
  text-align: left;
  font-size: 13px;
  padding: 0 10px;
  border-right: 1px solid #888888;
}

.lf_td01:last-child {
  border-right: 0px solid transparent;
}

.lf_td01,
.lf_td02 .checkboxes {
  font-size: 13px;
  color: #000000;
  line-height: 40px;
}

.lf_td01_middle {
  height: 120px !important;
  padding-top: 40px !important;
}

.lf_td02 {
  float: left;
  width: 14.2857142857%;
  border-right: 1px solid #888888;
}

.lf_td02:last-child {
  border-right: 0px solid transparent;
}

.lf_td_top01,
.lf_td_top02 {
  font-size: 13px;
  padding: 0px 10px;
  color: #ffffff;
  line-height: 30px;
  font-weight: 700;
  text-align: center;
}

.lf_tr_level01 .lf_td01 .checkboxes {
  padding-left: 10px;
  width: calc(100% + 2px);
}

.lf_tr_level02 .lf_td01 .checkboxes {
  padding-left: 30px;
  width: calc(100% + 2px);
}

.lf_tr_level03a .lf_td01 .checkboxes,
.lf_tr_level03b .lf_td01 .checkboxes {
  padding-left: 50px;
  width: calc(100% + 2px);
}

.lf_role_table tr:last-child {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.lf_tr_level01 .lf_td01:hover .checkboxes {
  background-color: #1e90ff;
  color: #ffffff;
}

.lf_tr_level02 .lf_td01:hover .checkboxes {
  background-color: #1e90ff;
  color: #ffffff;
}

.lf_tr_level03a .lf_td01:hover .checkboxes,
.lf_tr_level03b .lf_td01:hover .checkboxes {
  background-color: #1e90ff;
  color: #ffffff;
}

.lf_tr {
  float: left;
  width: 100%;
  min-height: 30px;
  z-index: 3;
}

.lf_tr_title {
  background: #444444;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.lf_td01 input {
  float: left;
  width: 100%;
  height: 20px;
  margin: 4px 0;
  border: 1px solid #888888;
  border-radius: 5px;
}

.lf_done_line {
  float: left;
  width: 100%;
  height: 22px;
  margin: 4px 0;
  border-radius: 5px;
  background: #2dae73;
  color: #ffffff;
  text-align: center;
  line-height: 24px;
  font-size: 12px;
  text-transform: uppercase;
  cursor: pointer;
}

.lf_done_line:hover {
  background: #1e8455;
}

.lf_tr_empty {
  float: left;
  width: 100%;
  height: 30px;
  background: #eeeeee;
  -webkit-box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: inset 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
}

.lf_tr_level01 {
  background: #ffffff;
}

.lf_tr_level02 {
  background: #b0b0b0;
}

.lf_tr_level03a {
  background: #eaeaea;
}

.lf_tr_level03b {
  background: #d4d4d4;
}

.lf_td_enable .switch {
  float: left;
  margin: 9px calc(50% - 16px);
}

.lf_tr_new {
  display: none;
  opacity: 0;
  transition: 0.5s all;
}

.lf_tr_new {
  display: block;
  opacity: 1;
}

.lf_drag_table {
  float: left;
  width: 15px;
  height: 24px;
  margin: 8px 0;
  cursor: move;
  background: #999999;
  border-radius: 3px;
  padding-left: 1px;
}

.lf_drag_table img {
  vertical-align: inherit;
}

.lf_table_arrow {
  float: right;
  width: 14px;
  height: 40px;
  padding: 5px 0;
}

.lf_updown {
  float: left;
  width: 100%;
  height: 12px;
  text-align: center;
  color: #4a4a4a;
  font-size: 22px;
  line-height: 12px;
  cursor: pointer;
  overflow: hidden;
}

.lf_updown_gap {
  float: left;
  width: 100%;
  height: 8px;
  cursor: inherit;
}

.lf_disable {
  opacity: 0.3;
}

.lf_drag_out {
  width: calc(100% + 20px);
  margin-left: -10px;
  position: relative;
  -webkit-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
  -moz-box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
  box-shadow: 0px 0px 10px 2px rgba(0, 0, 0, 0.4);
}

/*--- Button ---*/

.lf_btn_green {
  float: right;
  width: 100px;
  height: 30px;
  line-height: 30px !important;
  background-color: transparent !important;
  border: 1px solid #2dae73 !important;
  border-radius: 15px !important;
  color: #2dae73 !important;
  font-size: 13px !important;
  text-transform: uppercase;
  padding: 0px !important;
}

.lf_btn_green:hover {
  background-color: #2dae73 !important;
  color: #ffffff !important;
}

.lf_btn_red {
  float: left;
  width: 100px;
  height: 30px;
  line-height: 30px !important;
  background-color: transparent !important;
  border: 1px solid #cb5656 !important;
  border-radius: 15px !important;
  color: #cb5656 !important;
  font-size: 13px !important;
  text-transform: uppercase;
  padding: 0px !important;
}

.lf_btn_red:hover {
  background-color: #cb5656 !important;
  color: #ffffff !important;
}

.lf_row_btn {
  float: left;
  width: 100%;
  margin: 30px 0 30px 0;
}

.lf_btn_blu {
  float: right;
  width: 100px;
  height: 30px;
  margin-top: -5px;
  line-height: 30px !important;
  background-color: transparent !important;
  border: 1px solid #3d94ff !important;
  border-radius: 15px !important;
  color: #3d94ff !important;
  font-size: 13px !important;
  text-transform: uppercase;
  padding: 0px !important;
}

.lf_btn_blu:hover {
  background-color: #3d94ff !important;
  color: #ffffff !important;
}
