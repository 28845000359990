@import '~bootstrap/scss/functions';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';

.gatefy-search-tag {
  height: auto;
  padding: 0 $table-cell-padding-sm;

  &:focus-within {
    color: $input-focus-color;
    background-color: $input-focus-bg;
    border-color: $input-focus-border-color;
    outline: 0;
    // Avoid using mixin so we can pass custom focus shadow properly
    @if $enable-shadows {
      box-shadow: $input-box-shadow, $input-focus-box-shadow;
    } @else {
      box-shadow: $input-focus-box-shadow;
    }
  }

  .gatefy-alpha {
    border-bottom: 0 !important;
    padding: 0 !important;

    .gatefy-search-input {
      height: $input-height-sm;
    }
  }
}
