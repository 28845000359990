
/*--- arrow ---*/

.borderless {
  border: 0px solid transparent;
}

.arrow_right {
  transform: rotate(-45deg);
  -webkit-transform: rotate(-45deg);
}

.arrow_left {
  transform: rotate(135deg);
  -webkit-transform: rotate(135deg);
}

.arrow_up {
  transform: rotate(-135deg);
  -webkit-transform: rotate(-135deg);
}

.arrow_down {
  transform: rotate(45deg);
  -webkit-transform: rotate(45deg);
}
