.table-responsive-sm {
  overflow-x: auto;
  margin-bottom: 16px;

  table {
    margin-bottom: 0px;

    th, td {
      max-width: 300px;
      overflow: hidden;
      line-break: nowrap;
      text-overflow: ellipsis;
    }
  }
}