.check-styeled {
  transition: ease 0.2s;
  position: absolute;
  opacity: 0;
  height: 40px;
}

.check-styeled:checked ~ label.check-box {
  background: #fff;
  border: 2px solid #3f93fa;
}

.check-styeled:checked ~ label.check-box::before {
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  content: "\f00c";
  color: #3f93fa;
  position: absolute;
  top: -1px;
  left: 1px;
  align-items: center;
  justify-content: center;
  font-size: 10px;
}

.check-styeled ~ label.check-box {
  cursor: pointer;
  border: 2px solid #3f93fa;
  padding: 6px;
  border-radius: 3px;
  width: 15px;
  height: 15px;
  position: relative;
  font-weight: normal;
  position: relative;
  top: 2px;
  margin-right: 5px;
}
