
.detail-icons {
  opacity: .8;
}

table i {
  border: solid white;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
}

.icon {
  width: 20px;
  height: 20px;
  display: flex;
}

.icon-drag-dots {
  background: url('/assets/icones/dashboard/gtfy_drag_dots.png') no-repeat center center;
}

.icon-filter {
  background: url('/assets/icones/dashboard/gtfy_filter-off.png') no-repeat center center;
  background-size: contain;
}

.icon-filter:hover {
  background: url('/assets/icones/dashboard/gtfy_filter-hover.png') no-repeat center center;
  background-size: contain;
}

.icon-filter-active {
  background: url('/assets/icones/dashboard/gtfy_filter-on.png') no-repeat center center;
  background-size: contain;
}

.icon-info {
  background: url('/assets/icones/dashboard/gtfy_info-off.png') no-repeat center center;
  background-size: contain;
}

.icon-info:hover {
  background: url('/assets/icones/dashboard/gtfy_info-hover.png') no-repeat center center;
  background-size: contain;
}

.icon-info-active {
  background: url('/assets/icones/dashboard/gtfy_info-on.png') no-repeat center center;
  background-size: contain;
}
