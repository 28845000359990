.btn {
  &.btn-primary {
    background: #3f93fa;
    border: none;

    &:hover:not(:disabled) {
      background: #3c8bec;
    }

    &:focus:not(:disabled) {
      box-shadow: 0 0 0 0.2rem #3f93fa42 !important;
    }
  }

  &.btn-outline {
    &-primary {
      color: #3f93fa;
      border-color: #3f93fa;

      &:hover:not(:disabled) {
        background: #3c8bec;
        border-color: #3c8bec;
        color: white;
      }

      &:focus:not(:disabled) {
        box-shadow: 0 0 0 0.2rem #3f93fa42;
      }
    }
    &-secondary {
      color: #3f93fa;
      &:hover:not(:disabled) {
        background: #3c8bec;
        border-color: #3c8bec;
        color: white;
      }

      &:focus:not(:disabled) {
        box-shadow: 0 0 0 0.2rem #3f93fa42;
      }
      &:active:not(:disabled) {
        background: #3c8bec;
        &:focus:not(:disabled) {
          box-shadow: 0 0 0 0.2rem #3f93fa42;
        }
      }
    }
  }

  &.modal-btn {
    width: 129px;
    margin: 0px 13px;
    height: 43px;
    line-height: 0;
  }
}

.btn-outline-primary:not(:disabled):not(.disabled):active,
.btn-outline-primary:not(:disabled):not(.disabled).active,
.show > .btn-outline-primary.dropdown-toggle {
  background: #f4f4f4;
  background-color: #3f93fa;
}

.btn-outline-primary:not(:disabled):not(.disabled):active:focus,
.btn-outline-primary:not(:disabled):not(.disabled).active:focus,
.show > .btn-outline-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.2rem #3f93fa42;
}
